// Keys for different sections in section menu of vessel details page
export const SECTION_KEYS = {
  DASHBOARD: '1',
  PARTICULARS: '2',
  VESSEL_PLANS: '3',
  VESSEL_REPORTS: '4',
  GEO_LOCATOR: '5',
  ENGINE_PERFORMANCE: '6',
  VESSEL_INSPECTION: '7',
  DEFECT_LIST: '8',
  CREW_LIST: '9',
  CERTIFICATES: '10',
  PMS: '11',
  INTERNAL_AUDIT: '12',
  ACCOUNTING: '13',
  BUNKERING: '14',
  PURCHASE: '15',
  VESSEL_EXPENSE: '16',
  LUBE_OIL_ANALYSIS_REPORT: '17'
};

export const VALIDATION_MESSAGES = {
  IS_MANDATORY: 'Field value is mandatory.'
};

export const DATE_FORMAT = 'DD-MMM-YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_TIME_FORMAT = 'DD-MMM-YYYY HH:mm:ss';
export const DATE_FORMAT_UNICODE = 'dd/MM/y';

export const MIN_YEAR_IN_RANGE = 1990;

export const LAYER_KEYS = {
  ECA_LAYER: 1,
  LOADLINES_LAYER: 2,
  HIGH_RISK_AREAS: 3,
  SENSITIVE_AREAS: 4,
  WORLD_TIMEZONES: 5,
  JWC_LAYER: 6,
  EU_WATERS_LAYER: 7,
  PIRACY_RISK_LAYER: 8
};

export const DEFAULT_DATES = ['1900-01-01T00:00:00', '0001-01-01T00:00:00', '1753-01-01T00:00:00'];

export const SYSTEM_ADMIN_ROLE = 'System Admin';

export const SHIPNET_MANAGEMENT = ['Synergy Denmark'];

export const VESSEL_TABLE_COLUMS = [
  {
    id: 1,
    label: 'Crew List',
    Children: [
      {
        id: 11,
        label: 'Crew Onboard',
        Parent: 'Crew List'
      }
    ]
  },
  {
    id: 2,
    label: 'PMS',
    Children: [
      {
        id: 21,
        label: 'Critical Overdue',
        Parent: 'PMS'
      },
      {
        id: 22,
        label: 'Non critical WOs Overdue',
        Parent: 'PMS'
      },
      {
        id: 23,
        label: 'Spares Below ROL (Critical)',
        Parent: 'PMS'
      }
    ]
  },
  {
    id: 3,
    label: 'HSEQ',
    Children: [
      {
        id: 31,
        label: 'SIRE',
        Parent: 'HSEQ'
      },
      {
        id: 32,
        label: 'PSC',
        Parent: 'HSEQ'
      },
      {
        id: 33,
        label: 'FSI',
        Parent: 'HSEQ'
      },
      {
        id: 34,
        label: 'VIR',
        Parent: 'HSEQ'
      },
      {
        id: 35,
        label: 'IA',
        Parent: 'HSEQ'
      },
      {
        id: 36,
        label: 'Open Machinery Defect',
        Parent: 'HSEQ'
      }
    ]
  },
  {
    id: 4,
    label: 'Purchase',
    Children: [
      {
        id: 41,
        label: 'Committed POs',
        Parent: 'Purchase'
      }
    ]
  },
  {
    id: 5,
    label: 'Certificates',
    Children: [
      {
        id: 51,
        label: 'Open COC',
        Parent: 'Certificates'
      },
      {
        id: 52,
        label: 'Expired',
        Parent: 'Certificates'
      },
      {
        id: 53,
        label: 'Expiring',
        Parent: 'Certificates'
      }
    ]
  },
  {
    id: 6,
    label: 'Voyage',
    Children: [
      {
        id: 61,
        label: 'Last Noon Report Date',
        Parent: 'Voyage'
      },
      {
        id: 62,
        label: 'Attained EEXI',
        Parent: 'Voyage'
      },
      {
        id: 63,
        label: 'Attained CII',
        Parent: 'Voyage'
      },
      {
        id: 64,
        label: 'CII Rating',
        Parent: 'Voyage'
      },
      {
        id: 65,
        label: 'CII Reference Date',
        Parent: 'Voyage'
      }
    ]
  }
];

export const VESSEL_TABLE_COLUMS2 = [
  {
    id: 1,
    label: 'SIRE',
    Children: [
      {
        id: 11,
        label: 'SIRE_OPEN',
        Parent: 'SIRE'
      },
      {
        id: 12,
        label: 'SIRE_OVERDUE',
        Parent: 'SIRE'
      }
    ]
  },
  {
    id: 2,
    label: 'VIRE',
    Children: [
      {
        id: 13,
        label: 'VIRE_OPEN',
        Parent: 'VIRE'
      },
      {
        id: 14,
        label: 'VIRE_OVERDUE',
        Parent: 'VIRE'
      }
    ]
  },
  {
    id: 3,
    label: 'IA',
    Children: [
      {
        id: 15,
        label: 'IA_OPEN',
        Parent: 'IA'
      },
      {
        id: 16,
        label: 'IA_OVERDUE',
        Parent: 'IA'
      }
    ]
  }
];
export const REPORT_SECTION_KEYS = {
  HSEQ: '1',
  OTHER: '2'
};
